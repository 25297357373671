const HOME = "/";
const LOGIN = "/login";
const PROJECTS = "/workspaces/:workspaceId/projects/list";
const PROJECT_DASHBOARD = "/workspaces/:workspaceId/projects/:id/dashboard";
const PROJECT_DATA = "/workspaces/:workspaceId/projects/:id/data";
const PROJECT_MODELS = "/workspaces/:workspaceId/projects/:id/models";
const NEW_PROJECT = "/workspaces/:workspaceId/projects/new-project";
const PIPELINES = "/workspaces/:workspaceId/pipelines";
const PIPELINE_INFO = "/workspaces/:workspaceId/pipelines/:pipelineId";
const PIPELINE_EDIT = "/workspaces/:workspaceId/pipelines/:pipelineId/edit";
const PIPELINE_NEW = "/workspaces/:workspaceId/pipelines/new";
const AGENT_CONFIGURATION = "/workspaces/:workspaceId/pipelines/:pipelineId/configuration";
const AGENT_TESTING = "/workspaces/:workspaceId/pipelines/:pipelineId/testing";
const KNOWLEDGE_BASE = "/workspaces/:workspaceId/knowledge-base";
const KNOWLEDGE_BASE_BY_ID = "/workspaces/:workspaceId/knowledge-base/:knowledgeBaseId";
const WORKSPACES = "/workspaces";
const WORKSPACE_INFO = "/workspaces/:workspaceId";
const AUTOMATION = "/workspaces/:workspaceId/automation";
const USER_PROFILE = "/profile";
const WORKSPACE_EDIT = "/workspaces/:workspaceId/edit";
const PROJECTS_PAGE = "/workspaces/:workspaceId/projects";

export const ROUTES = {
  HOME,
  LOGIN,
  NEW_PROJECT,
  PROJECTS,
  PROJECT_DASHBOARD,
  PROJECT_DATA,
  PROJECT_MODELS,
  PIPELINES,
  PIPELINE_INFO,
  PIPELINE_EDIT,
  PIPELINE_NEW,
  AGENT_CONFIGURATION,
  AGENT_TESTING,
  KNOWLEDGE_BASE,
  KNOWLEDGE_BASE_BY_ID,
  WORKSPACES,
  WORKSPACE_INFO,
  AUTOMATION,
  USER_PROFILE,
  WORKSPACE_EDIT,
  PROJECTS_PAGE,
};
