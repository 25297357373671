import React, { useState, useEffect } from 'react';
import { notification, Card, Typography, Space } from 'antd';
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import { fetchUserProfile, updateUserProfile } from '../../connectors/api';
import Layout from '../../components/Layout/Layout';
import FormInput from '../../components/FormInput/FormInput';
import Button from '../../components/Button/Button';
import styled from 'styled-components';

const { Title } = Typography;

const StyledCard = styled(Card)`
  max-width: 600px;
  margin: 2rem auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
`;

const UserConfiguration = () => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const data = await fetchUserProfile();
        setUserData(data);
      } catch (error) {
        notification.error({ message: 'Failed to load user data' });
      }
    };
    loadUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      await updateUserProfile(userData);
      notification.success({ message: 'Profile updated successfully' });
    } catch (error) {
      notification.error({ message: 'Failed to update profile' });
    }
  };

  return (
    <Layout title="User Configuration">
      <StyledCard>
        <StyledForm>
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <FormInput
              label="Username"
              type="text"
              value={userData.username || ''}
              onChange={handleInputChange}
              id="username"
              required
              prefix={<UserOutlined />}
              placeholder="Enter your username"
            />
            <Button 
              onClick={handleSubmit}
              style={{ 
                width: '100%',
                height: '40px',
                marginTop: '1rem',
                background: '#1890ff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                fontSize: '16px'
              }}
            >
              Save Changes
            </Button>
          </Space>
        </StyledForm>
      </StyledCard>
    </Layout>
  );
};

export default UserConfiguration;