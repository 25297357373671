import React from "react";
import { useNavigate } from "react-router-dom";

import ProjectCard from "../../components/ProjectCard/ProjectCard";
import { getUrl } from "../../helpers/navigation";
import { ROUTES } from "../../constants/routes";

// TODO: check with Albert, maybe will be removed
const WorkspaceInfoProjects = ({ projects, workspaceId }) => {
  const navigate = useNavigate();

  const handleNewProject = () => {
    navigate(getUrl(ROUTES.NEW_PROJECT, { workspaceId }));
  };

  return (
    <div>
      <h2 className="section-title">Recent Projects</h2>
      {projects?.length > 0 ? (
        <div className="items-grid">
          {projects.map(({ id, name, updated_at, image }) => (
            <ProjectCard
              key={id}
              project={{
                id,
                name,
                created_at: updated_at,
                image,
                workspaceId,
              }}
            />
          ))}
        </div>
      ) : (
        <p className="no-items-text" onClick={handleNewProject}>
          No projects available. Click here to start something amazing!
        </p>
      )}
    </div>
  );
};

export default WorkspaceInfoProjects;
