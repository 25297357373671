// PROD
const HOST = "https://platform.somiasolutions.com";
const RAGFLOW_HOST = "https://ragflow.somiasolutions.com";

// LOCAL HOST
// const HOST = "http://localhost";

// API endpoints
const CHECK_TOKEN = "/api/auth/check-token";
const DASHBOARD_DATA = "/api/api/workspaces/:workspaceId/projects/:id/dashboard";
const LOGIN = "/api/auth/login";
const REFRESH_TOKEN = "/api/auth/refresh";
const PREDICTION_AVAILABLE_CHECK = "/api/api/workspaces/:workspaceId/projects/:id/check-models";
const LOGIN_LABEL_STUDIO = "/api/auth/login-label-studio";
const PROJECTS_LIST = "/api/api/workspaces/:workspaceId/projects";
const PROJECT_INFO_BY_ID = "/api/api/workspaces/:workspaceId/projects/:id";
const PROJECT_MODEL = "/api/api/workspaces/:workspaceId/projects/:id/models";
const TRIGGER_EVAL_MODEL = "/api/api/workspaces/:workspaceId/projects/:id/eval";
const TRIGGER_GET_PREDICTION = "/api/api/workspaces/:workspaceId/projects/:id/get-predictions";
const TRIGGER_PROJECT_DATA_UPLOAD = "/api/api/workspaces/:workspaceId/projects/:id/upload";
const TRIGGER_TRAIN_MODEL = "/api/api/workspaces/:workspaceId/projects/:id/train";
const TEMPLATE_PIPELINES = "/api/api/pipeline-templates";
const PIPELINES = "/api/api/workspaces/:workspaceId/pipelines";
const PIPELINE_BY_ID = "/api/api/workspaces/:workspaceId/pipelines/:pipelineId";
const LOGIN_RAGFLOW = "/api/auth/login-ragflow";
const WORKSPACES = "/api/api/workspaces";
const WORKSPACES_BY_ID = "/api/api/workspaces/:workspaceId";
const KNOWLEDGE_BASE_CREATE = "/api/api/workspaces/:workspaceId/knowledge-bases";
const KNOWLEDGE_BASE_LIST = "/api/api/workspaces/:workspaceId/knowledge-bases";
const KNOWLEDGE_BASE_BY_ID = "/api/api/workspaces/:workspaceId/knowledge-bases/:knowledgeBaseId";
const USER_PROFILE = "/api/api/profile";
const USER_PROFILE_UPDATE = "/api/api/profile";
const RAGFLOW_API_TOKEN = "/api/auth/ragflow-api-token";

export const CHECK_TOKEN_FETCH_URL = HOST + CHECK_TOKEN;
export const DASHBOARD_DATA_FETCH_URL = HOST + DASHBOARD_DATA;
export const LOGIN_FETCH_URL = HOST + LOGIN;
export const REFRESH_TOKEN_FETCH_URL = HOST + REFRESH_TOKEN;
export const LOGIN_LABEL_STUDIO_FETCH_URL = HOST + LOGIN_LABEL_STUDIO;
export const PROJECTS_LIST_FETCH_URL = HOST + PROJECTS_LIST;
export const PROJECT_INFO_BY_ID_FETCH_URL = HOST + PROJECT_INFO_BY_ID;
export const TRIGGER_EVAL_MODEL_FETCH_URL = HOST + TRIGGER_EVAL_MODEL;
export const TRIGGER_GET_PREDICTION_FETCH_URL = HOST + TRIGGER_GET_PREDICTION;
export const TRIGGER_TRAIN_MODEL_FETCH_URL = HOST + TRIGGER_TRAIN_MODEL;
export const TRIGGER_PROJECT_DATA_UPLOAD_FETCH_URL =
  HOST + TRIGGER_PROJECT_DATA_UPLOAD;
export const PREDICTION_AVAILABLE_CHECK_FETCH_URL =
  HOST + PREDICTION_AVAILABLE_CHECK;
export const PROJECT_MODEL_FETCH_URL = HOST + PROJECT_MODEL;
export const PIPELINES_FETCH_URL = HOST + PIPELINES;
export const PIPELINE_CREATE_URL = HOST + PIPELINES;
export const PIPELINE_BY_ID_URL = HOST + PIPELINE_BY_ID;
export const LOGIN_RAGFLOW_FETCH_URL = HOST + LOGIN_RAGFLOW;
export const WORKSPACES_FETCH_URL = HOST + WORKSPACES;
export const WORKSPACES_CREATE_URL = HOST + WORKSPACES;
export const WORKSPACES_BY_ID_URL = HOST + WORKSPACES_BY_ID;
export const KNOWLEDGE_BASE_CREATE_URL = HOST + KNOWLEDGE_BASE_CREATE;
export const KNOWLEDGE_BASE_LIST_URL = HOST + KNOWLEDGE_BASE_LIST;
export const KNOWLEDGE_BASE_BY_ID_URL = HOST + KNOWLEDGE_BASE_BY_ID;
export const TEMPLATE_PIPELINES_FETCH_URL = HOST + TEMPLATE_PIPELINES;
export const USER_PROFILE_FETCH_URL = HOST + USER_PROFILE;
export const USER_PROFILE_UPDATE_URL = HOST + USER_PROFILE_UPDATE;
export const RAGFLOW_API_TOKEN_FETCH_URL = HOST + RAGFLOW_API_TOKEN;
export const RAGFLOW_HOST_URL = RAGFLOW_HOST;