import React, { useRef, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { fetchKnowledgeBaseById } from "../../connectors/api";
import { fetchLoginRagFlow } from "../../connectors/auth";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { RAGFLOW_HOST_URL } from "../../constants/endpoints";

const MAX_NUMBER_OF_RETRIES = 5; // Define a constant for max retries

const ConfigIframeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  top: -80px;
`;

const ConfigIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 200px;
  width: 100%;
  height: 180px;
  background: #f5f5f5;
  z-index: 1000;
  pointer-events: none;
`;

const ParentContainer = styled.div`
  overflow: hidden;
`;

const FailedLoadingWrapper = styled.div`
  max-width: 600px;
  margin: auto;
  text-align: center;
  padding: 40px 20px;

  .failed-loading-title {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }

  .failed-loading-subtitle {
    line-height: 1.6;
    color: #666;
    font-size: 16px;
  }
`;

const KnowledgeBasePage = () => {
  const { workspaceId, knowledgeBaseId } = useParams();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 'dataset';
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [knowledgeBase, setKnowledgeBase] = useState(null);
  const [error, setError] = useState(null);
  const [ragflowKnowledgeBaseId, setRagflowKnowledgeBaseId] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  const loginRagFlow = async () => {
    let count = 0;
    while (count < MAX_NUMBER_OF_RETRIES) {
      try {
        count += 1;
        const response = await fetchLoginRagFlow();
        setAuthToken(response.authorization_key);
        return true;
      } catch (error) {
        console.error("Retrying login to RagFlow", count);
      }
    }
    console.error("Failed to log in to RagFlow after multiple attempts");
    return false;
  };

  useEffect(() => {
    const fetchKnowledgeBaseInfo = async () => {
      try {
        const response = await fetchKnowledgeBaseById(workspaceId, knowledgeBaseId);
        console.log(response);
        setKnowledgeBase(response);
        setIsLoading(false);
        setRagflowKnowledgeBaseId(response.id);
        console.log(response.id);
        console.log(ragflowKnowledgeBaseId);
        return true;
      } catch (error) {
        console.error("Failed to fetch Knowledge base info:", error);
        setError(error);
        setIsLoading(false);
        return false;
      }
    };

    (async () => {
      const success = await fetchKnowledgeBaseInfo();
      if (!success) return;
      await loginRagFlow();
      setIsLoading(false);
    })();
    
    return () => {};
  }, [workspaceId, knowledgeBaseId]);

  if (error) {
    return (
      <Layout title="Knowledge Base Configuration" isLoading={isLoading}>
        <FailedLoadingWrapper>
          <h2 className="failed-loading-title">
            Failed to load RagFlow configuration
          </h2>
          <p className="failed-loading-subtitle">
            Please try to reload this page or contact us to resolve your problem
            as soon as possible! Sorry for inconveniences and have a great day!
          </p>
        </FailedLoadingWrapper>
      </Layout>
    );
  }

  return (
    <Layout
      title={`${knowledgeBase?.name || 'Knowledge Base'} Configuration`}
      isLoading={isLoading}
    >
      <ParentContainer>
        <ConfigIframeWrapper>
          <ConfigIframe
            ref={iframeRef}
            src={`${RAGFLOW_HOST_URL}/knowledge/${page}?id=${ragflowKnowledgeBaseId}&auth=${encodeURIComponent(authToken)}`}
            title="Knowledge base configuration"
          />
        </ConfigIframeWrapper>
      </ParentContainer>
    </Layout>
  );
};

export default KnowledgeBasePage;