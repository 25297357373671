import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { RAGFLOW_HOST_URL } from "../../constants/endpoints";
import { fetchPipelineById } from "../../connectors/api";
import { fetchLoginRagFlow } from "../../connectors/auth";

const MAX_NUMBER_OF_RETRIES = 5; // Define a constant for max retries

const ConfigIframeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  top: -80px;
`;

const ConfigIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 200px;
  width: 100%;
  height: 180px;
  background: #f5f5f5;
  z-index: 1000;
  pointer-events: none;
`;

const ParentContainer = styled.div`
  overflow: hidden;
`;

const FailedLoadingWrapper = styled.div`
  max-width: 600px;
  margin: auto;
  text-align: center;
  padding: 40px 20px;

  .failed-loading-title {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }

  .failed-loading-subtitle {
    line-height: 1.6;
    color: #666;
    font-size: 16px;
  }
`;

const AgentConfigurationPage = () => {
  const { workspaceId, pipelineId } = useParams();
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pipeline, setPipeline] = useState(null);
  const [error, setError] = useState(null);
  const [ragflowAgentId, setRagflowAgentId] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  const loginRagFlow = async () => {
    let count = 0;
    while (count < MAX_NUMBER_OF_RETRIES) {
      try {
        count += 1;
        const response = await fetchLoginRagFlow(); // Call the new login function
        console.log("Successfully logged in to RagFlow");
        console.log("Response:", response);
        console.log("Auth key:", response.authorization_key);
        setAuthToken(response.authorization_key);
        return true;
      } catch (error) {
        console.error("Retrying login to RagFlow", count);
      }
    }
    console.error("Failed to log in to RagFlow after multiple attempts");
    return false;
  };

  useEffect(() => {
    const fetchPipelineInfo = async () => {
      try {
        const response = await fetchPipelineById(workspaceId, pipelineId);
        setPipeline(response);
        setIsLoading(false);
        console.log("Pipeline info fetched:", response);
        setRagflowAgentId(response.ragflow_agent_id);
      } catch (error) {
        console.error("Failed to fetch Pipeline info:", error);
        setError(error);
        setIsLoading(false);
      }
    };

    loginRagFlow();
    fetchPipelineInfo();

    return () => {};
  }, [workspaceId, pipelineId]);

  const handleIframeLoad = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      
      console.log("Iframe document:", iframeDocument);
      // Check if the iframe is on the login page
      if (iframeDocument.querySelector('form.login-form')) {
        // Fill in the login form
        const usernameInput = iframeDocument.querySelector('input[name="username"]');
        const passwordInput = iframeDocument.querySelector('input[name="password"]');
        const loginButton = iframeDocument.querySelector('button[type="submit"]');

        console.log("Username input:", usernameInput);
        console.log("Password input:", passwordInput);
        console.log("Login button:", loginButton);
  
        if (usernameInput && passwordInput && loginButton) {
          usernameInput.value = 'your-username';
          passwordInput.value = 'your-password';
          loginButton.click();
        }
      }
    }
  };

  if (error) {
    return (
      <Layout title="Pipeline Configuration" isLoading={isLoading}>
        <FailedLoadingWrapper>
          <h2 className="failed-loading-title">
            Failed to load RagFlow configuration
          </h2>
          <p className="failed-loading-subtitle">
            Please try to reload this page or contact us to resolve your problem
            as soon as possible! Sorry for inconveniences and have a great day!
          </p>
        </FailedLoadingWrapper>
      </Layout>
    );
  }

  return (
    <Layout
      title={`${pipeline?.name || 'Pipeline'} Configuration`}
      isLoading={isLoading}
    >
      <ParentContainer>
        <ConfigIframeWrapper>
          <ConfigIframe
            ref={iframeRef}
            src={`${RAGFLOW_HOST_URL}/flow/${ragflowAgentId}?auth=${encodeURIComponent(authToken)}`}
            title="Pipeline configuration"
            // onLoad={handleIframeLoad}
          />
        </ConfigIframeWrapper>
      </ParentContainer>
    </Layout>
  );
};

export default AgentConfigurationPage;