import React, { useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import cn from "classnames";
import {
  FaHome,
  FaUser,
  FaSignOutAlt,
  FaArrowAltCircleLeft,
  FaBorderAll,
  FaAtom,
  FaStream,
  FaInfoCircle,
  FaCogs,
  FaVial,
  FaTable,
  FaRobot,
  FaBrain,
  FaChevronRight,
  FaDatabase,
} from "react-icons/fa";

import Logo from "../Logo/Logo";
import SidebarStyledWrapper from "./SidebarStyledWrapper";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin: 30px 0;
`;

const linkStyles = `
  text-decoration: none;
  color: #444;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 4px;
  position: relative;
  font-weight: 500;

  svg {
    margin-right: 8px;
    color: #888;
    font-size: 16px;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;

const StyledNavLink = styled(NavLink)`
  ${linkStyles}

  &.active-link {
    color: #111;
    background-color: #ebf3ff;
    border-left: 4px solid #3b82f6;
    padding-left: 6px;

    svg {
      color: #3b82f6;
    }
  }
`;

const SubMenuToggle = styled.div`
  ${linkStyles}
  cursor: pointer;

  &.active {
    background-color: #ebf3ff;
    border-left: 4px solid #3b82f6;
    padding-left: 6px;
    color: #111;

    svg.icon {
      color: #3b82f6;
    }
  }

  svg.chevron {
    margin-left: auto;
    transition: transform 0.3s;
    color: #888;
    font-size: 12px;

    &.open {
      transform: rotate(90deg);
    }
  }
`;

const SubMenuList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  &.submenu-level-1 {
    margin-left: 20px;
    border-left: 1px solid #eee;
    padding-left: 10px;
  }

  &.submenu-level-2 {
    margin-left: 20px;
    border-left: 1px dashed #eee;
    padding-left: 10px;
    font-size: 13px;
    color: #555;
  }

  li {
    margin-bottom: 3px;
  }
`;

const homeSideMenu = [
  {
    icon: <FaHome />,
    path: ROUTES.WORKSPACES,
    label: "Workspaces",
  },
  {
    icon: <FaUser />,
    path: "/profile",
    label: "Profile",
  },
  {
    icon: <FaSignOutAlt />,
    path: ROUTES.LOGIN,
    label: "Logout",
    onClick: () => localStorage.removeItem("token"),
  },
];

const workspaceMenuConfig = [
  {
    icon: <FaBorderAll />,
    path: ROUTES.WORKSPACE_INFO,
    label: "Overview",
  },
  {
    icon: <FaAtom />,
    path: ROUTES.PROJECTS_PAGE,
    label: "Models",
    hasSubmenu: true,
  },
  {
    icon: <FaStream />,
    path: ROUTES.AUTOMATION,
    label: "Automation",
    hasSubmenu: true,
  },
];

const modelsMenuConfig = [
  {
    icon: <FaBorderAll />,
    path: ROUTES.PROJECTS_PAGE,
    label: "Overview",
  },
  {
    icon: <FaAtom />,
    path: ROUTES.PROJECTS,
    label: "All Projects",
  },
];

const projectMenuConfig = [
  {
    icon: <FaBorderAll />,
    path: ROUTES.PROJECT_DASHBOARD,
    label: "Dashboard",
  },
  {
    icon: <FaAtom />,
    path: ROUTES.PROJECT_MODELS,
    label: "Models",
  },
  {
    icon: <FaDatabase />,
    path: ROUTES.PROJECT_DATA,
    label: "Data",
  },
];

const automationMenuConfig = [
  {
    icon: <FaBorderAll />,
    path: ROUTES.AUTOMATION,
    label: "Overview",
  },
  {
    icon: <FaRobot />,
    path: ROUTES.PIPELINES,
    label: "All Agents",
  },
  {
    icon: <FaBrain />,
    path: ROUTES.KNOWLEDGE_BASE,
    label: "Knowledge Bases",
  },
];

const pipelinesMenuConfig = [
  {
    icon: <FaInfoCircle />,
    path: ROUTES.PIPELINE_INFO,
    label: "Pipeline Info",
  },
  {
    icon: <FaCogs />,
    path: ROUTES.AGENT_CONFIGURATION,
    label: "Pipeline Configuration",
  },
  {
    icon: <FaVial />,
    path: ROUTES.AGENT_TESTING,
    label: "Pipeline Testing",
  },
];

const knowledgeBaseMenuConfig = [
  {
    icon: <FaTable />,
    path: `${ROUTES.KNOWLEDGE_BASE_BY_ID}?page=dataset`,
    label: "Dataset",
  },
  {
    icon: <FaCogs />,
    path: `${ROUTES.KNOWLEDGE_BASE_BY_ID}?page=configuration`,
    label: "Configuration",
  },
  {
    icon: <FaVial />,
    path: `${ROUTES.KNOWLEDGE_BASE_BY_ID}?page=testing`,
    label: "Testing",
  },
];

const isWorkspaceDetails = (pathname) => {
  const pathArray = pathname.split("/");
  return pathArray[1] === "workspaces" && !isNaN(pathArray[2]);
};

const Sidebar = () => {
  const params = useParams();
  const location = useLocation();

  const isHomePath = !isWorkspaceDetails(location.pathname);
  const isPipelineSelected = params.pipelineId;
  const isKnowledgeBaseSelected = params.knowledgeBaseId;
  const isProjectSelected = params.id;
  const isAutomationSelected =
    location.pathname.includes("/automation") ||
    location.pathname.includes("/pipelines") ||
    location.pathname.includes("/kb");
  const isModelsSelected = location.pathname.includes("/projects");

  const menuConfig = isHomePath ? homeSideMenu : workspaceMenuConfig;
  const isActive = (path) => location.pathname === getUrl(path, params);

  const [automationOpen, setAutomationOpen] = useState(isAutomationSelected);
  const [modelsOpen, setModelsOpen] = useState(isModelsSelected);

  const renderMenuItems = (menuConfig) =>
    menuConfig.map(({ icon, path, label, onClick, hasSubmenu }) => {
      const active =
        isActive(path) ||
        (isAutomationSelected && path === ROUTES.AUTOMATION) ||
        (isModelsSelected && path === ROUTES.PROJECTS);

      if (hasSubmenu) {
        if (path === ROUTES.PROJECTS || path === ROUTES.PROJECTS_PAGE) {
          // Models menu
          return (
            <React.Fragment key={path}>
              <li className="sidebar-navigation-item">
                <SubMenuToggle className={cn({ active })} onClick={() => setModelsOpen(!modelsOpen)}>
                  {icon} {label}
                  <FaChevronRight className={cn("chevron", { open: modelsOpen })} />
                </SubMenuToggle>
              </li>
              {modelsOpen && (
                <SubMenuList className="submenu-level-1">
                  {modelsMenuConfig.map(({ icon, path: subPath, label }) => {
                    const subItemActive = isActive(subPath);
                    return (
                      <React.Fragment key={subPath}>
                        <li className="sidebar-navigation-item submenu-item">
                          <StyledNavLink to={getUrl(subPath, params)} className={cn({ "active-link": subItemActive })}>
                            {icon} {label}
                          </StyledNavLink>
                        </li>
                        {/* If this is the "All Projects" item and a project is selected, show project submenu */}
                        {label === "All Projects" && isProjectSelected && (
                          <SubMenuList className="submenu-level-2">
                            {projectMenuConfig.map(({ icon, path: projPath, label: projLabel }) => (
                              <li className="sidebar-navigation-item submenu-item" key={projPath}>
                                <StyledNavLink
                                  to={getUrl(projPath, params)}
                                  className={cn({ "active-link": isActive(projPath) })}
                                >
                                  {icon} {projLabel}
                                </StyledNavLink>
                              </li>
                            ))}
                          </SubMenuList>
                        )}
                      </React.Fragment>
                    );
                  })}
                </SubMenuList>
              )}
            </React.Fragment>
          );
        }
        // Automation menu
        return (
          <React.Fragment key={path}>
            <li className="sidebar-navigation-item">
              <SubMenuToggle className={cn({ active })} onClick={() => setAutomationOpen(!automationOpen)}>
                {icon} {label}
                <FaChevronRight className={cn("chevron", { open: automationOpen })} />
              </SubMenuToggle>
            </li>
            {automationOpen && (
              <SubMenuList className="submenu-level-1">
                {automationMenuConfig.map(({ icon, path: subPath, label }) => {
                  const subItemActive = isActive(subPath);
                  return (
                    <React.Fragment key={subPath}>
                      <li className="sidebar-navigation-item submenu-item">
                        <StyledNavLink to={getUrl(subPath, params)} className={cn({ "active-link": subItemActive })}>
                          {icon} {label}
                        </StyledNavLink>
                      </li>
                      {/* If this is the "All Agents" item and a pipeline is selected, show pipeline submenu right after it */}
                      {label === "All Agents" && isPipelineSelected && (
                        <SubMenuList className="submenu-level-2">
                          {pipelinesMenuConfig.map(({ icon, path: ppPath, label: ppLabel }) => (
                            <li className="sidebar-navigation-item submenu-item" key={ppPath}>
                              <StyledNavLink
                                to={getUrl(ppPath, params)}
                                className={cn({ "active-link": isActive(ppPath) })}
                              >
                                {icon} {ppLabel}
                              </StyledNavLink>
                            </li>
                          ))}
                        </SubMenuList>
                      )}
                      {/* If this is the "Knowledge Bases" item and a KB is selected, show KB submenu right after it */}
                      {label === "Knowledge Bases" && isKnowledgeBaseSelected && (
                        <SubMenuList className="submenu-level-2">
                          {knowledgeBaseMenuConfig.map(({ icon, path: kbPath, label: kbLabel }) => (
                            <li className="sidebar-navigation-item submenu-item" key={kbPath}>
                              <StyledNavLink
                                to={getUrl(kbPath, params)}
                                className={cn({
                                  "active-link": location.search.includes(kbPath.split("?")[1]),
                                })}
                              >
                                {icon} {kbLabel}
                              </StyledNavLink>
                            </li>
                          ))}
                        </SubMenuList>
                      )}
                    </React.Fragment>
                  );
                })}
              </SubMenuList>
            )}
          </React.Fragment>
        );
      }

      return (
        <li className="sidebar-navigation-item" key={path}>
          <StyledNavLink to={getUrl(path, params)} className={cn({ "active-link": isActive(path) })} onClick={onClick}>
            {icon} {label}
          </StyledNavLink>
        </li>
      );
    });

  return (
    <SidebarStyledWrapper $isHomePath={isHomePath}>
      <div className="sidebar-header">
        <Logo className="logo" />
        <span className="sidebar-platform-name">PLATFORM</span>
      </div>
      <Divider />
      <nav className="sidebar-navigation">
        <ul className="sidebar-navigation-list">
          {!isHomePath && (
            <li>
              <StyledNavLink to={ROUTES.WORKSPACES}>
                <FaArrowAltCircleLeft /> Back to Workspaces
              </StyledNavLink>
            </li>
          )}
          {renderMenuItems(menuConfig)}
        </ul>
      </nav>
    </SidebarStyledWrapper>
  );
};

export default Sidebar;
