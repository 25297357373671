import React from "react";
import { Navigate, BrowserRouter as Router, Route, Routes } from "react-router-dom";

import DashboardPage from "./pages/ProjectDashboard/DashboardPage";
import DataPage from "./pages/ProjectData/DataPage";
import ProjectsList from "./pages/ProjectsList/ProjectsList";
import LoginPage from "./pages/Auth/LoginPage/LoginPage";
import ModelsPage from "./pages/ProjectModels/ModelsPage";
import NewProjectPage from "./pages/newProject/NewProjectPage";
import PipelinesPage from "./pages/ProjectPipelines/PipelinesPage";
import PipelineInfoPage from "./pages/ProjectPipelines/PipelineInfoPage";
import PipelineEditPage from "./pages/ProjectPipelines/EditPipelinePage";
import NewPipelinePage from "./pages/ProjectPipelines/NewPipelinePage";
import AgentConfigurationPage from "./pages/Agent/AgentConfigurationPage";
import AgentTestingPage from "./pages/Agent/AgentTestingPage";
import KnowledgeBasePage from "./pages/KnowledgeBasePage/KnowledgeBasePage";
import KnowledgeBaseByIdPage from "./pages/KnowledgeBasePage/KnowledgeBaseInfoPage";
import WorkspacesPage from "./pages/Workspaces/WorkspacesPage";
import WorkspacesInfoPage from "./pages/WorkspaceInfo/WorkspaceInfo";
import AutomationPage from "./pages/Automation/AutomationPage";
import UserConfigurationPage from "./pages/UserConfiguration/UserConfiguration";
import WorkspaceEditPage from "./pages/Workspaces/WorkspacesEditPage";
import ProjectsPage from "./pages/Projects/Projects";
import { ROUTES } from "./constants/routes";
import withAuth from "./HOCs/withAuth";

const {
  HOME,
  LOGIN,
  NEW_PROJECT,
  PROJECTS,
  PROJECT_DASHBOARD,
  PROJECT_DATA,
  PROJECT_MODELS,
  PIPELINES,
  PIPELINE_INFO,
  PIPELINE_EDIT,
  PIPELINE_NEW,
  AGENT_CONFIGURATION,
  AGENT_TESTING,
  KNOWLEDGE_BASE,
  KNOWLEDGE_BASE_BY_ID,
  WORKSPACES,
  WORKSPACE_INFO,
  AUTOMATION,
  USER_PROFILE,
  WORKSPACE_EDIT,
  PROJECTS_PAGE,
} = ROUTES;

const PrivateDashboardPage = withAuth(DashboardPage);
const PrivateDataPage = withAuth(DataPage);
const PrivateModelsPage = withAuth(ModelsPage);
const PrivateNewProjectPage = withAuth(NewProjectPage);
const PrivatePipelinesPage = withAuth(PipelinesPage);
const PrivatePipelineInfoPage = withAuth(PipelineInfoPage);
const PrivatePipelineEditPage = withAuth(PipelineEditPage);
const PrivateNewPipelinePage = withAuth(NewPipelinePage);
const PrivateAgentConfigurationPage = withAuth(AgentConfigurationPage);
const PrivateAgentTestingPage = withAuth(AgentTestingPage);
const PrivateKnowledgeBasePage = withAuth(KnowledgeBasePage);
const PrivateKnowledgeBaseByIdPage = withAuth(KnowledgeBaseByIdPage);
const PrivateWorkspacesPage = withAuth(WorkspacesPage);
const PrivateWorkspaceInfoPage = withAuth(WorkspacesInfoPage);
const PrivateAutomationPage = withAuth(AutomationPage);
const PrivateUserConfigurationPage = withAuth(UserConfigurationPage);
const PrivateWorkspaceEditPage = withAuth(WorkspaceEditPage);
const PrivateProjectsPage = withAuth(ProjectsPage);
const PrivateProjectsPageList = withAuth(ProjectsList);

const App = () => (
  <div className="app">
    <Router>
      <Routes>
        <Route path={LOGIN} element={<LoginPage />} />
        <Route path={HOME} element={<Navigate to={WORKSPACES} replace />} />
        <Route path={AGENT_CONFIGURATION} element={<PrivateAgentConfigurationPage />} />
        <Route path={AGENT_TESTING} element={<PrivateAgentTestingPage />} />
        <Route path={AUTOMATION} element={<PrivateAutomationPage />} />
        <Route path={KNOWLEDGE_BASE_BY_ID} element={<PrivateKnowledgeBaseByIdPage />} />
        <Route path={KNOWLEDGE_BASE} element={<PrivateKnowledgeBasePage />} />
        <Route path={NEW_PROJECT} element={<PrivateNewProjectPage />} />
        <Route path={PIPELINES} element={<PrivatePipelinesPage />} />
        <Route path={PIPELINE_EDIT} element={<PrivatePipelineEditPage />} />
        <Route path={PIPELINE_INFO} element={<PrivatePipelineInfoPage />} />
        <Route path={PIPELINE_NEW} element={<PrivateNewPipelinePage />} />
        <Route path={PROJECTS_PAGE} element={<PrivateProjectsPage />} />
        <Route path={PROJECTS} element={<PrivateProjectsPageList />} />
        <Route path={PROJECT_DASHBOARD} element={<PrivateDashboardPage />} />
        <Route path={PROJECT_DATA} element={<PrivateDataPage />} />
        <Route path={PROJECT_MODELS} element={<PrivateModelsPage />} />
        <Route path={USER_PROFILE} element={<PrivateUserConfigurationPage />} />
        <Route path={WORKSPACES} element={<PrivateWorkspacesPage />} />
        <Route path={WORKSPACE_EDIT} element={<PrivateWorkspaceEditPage />} />
        <Route path={WORKSPACE_INFO} element={<PrivateWorkspaceInfoPage />} />
      </Routes>
    </Router>
  </div>
);

export default App;
