import {
  CHECK_TOKEN_FETCH_URL,
  LOGIN_FETCH_URL,
  LOGIN_LABEL_STUDIO_FETCH_URL,
  LOGIN_RAGFLOW_FETCH_URL,
  REFRESH_TOKEN_FETCH_URL,
} from "../constants/endpoints";
import { fetchWithTokenRefresh } from "../helpers/fetchWithTokenRefresh";


export const fetchCheckToken = async () => {
  const response = await fetchWithTokenRefresh(CHECK_TOKEN_FETCH_URL);
  return response.json();
};

export const fetchLogin = async (username, password) => {
  const response = await fetch(LOGIN_FETCH_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });

  if (response.ok) {
    const parsedResponse = await response.json();
    localStorage.setItem("token", parsedResponse.access_token);
    localStorage.setItem("refresh_token", parsedResponse.refresh_token);

    return parsedResponse;
  }

  throw new Error("Wrong username or password");
};

export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token");
  const response = await fetch(REFRESH_TOKEN_FETCH_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshToken}`,
    },
  });

  if (response.ok) {
    const parsedResponse = await response.json();
    localStorage.setItem("token", parsedResponse.access_token);
    return parsedResponse.access_token;
  }

  throw new Error("Failed to refresh access token");
};

export const fetchLoginLabelStudio = async () => {
  const response = await fetchWithTokenRefresh(LOGIN_LABEL_STUDIO_FETCH_URL);
  if (response.ok) {
    return response.json();
  }
  throw new Error("Error logging in to Label Studio");
};

export const fetchLoginRagFlow = async () => {
  const response = await fetchWithTokenRefresh(LOGIN_RAGFLOW_FETCH_URL);
  if (response.ok) {
    return response.json();
  }
  throw new Error("Error logging in to RagFlow"); 
};