import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Modal, Form, Input, Select, notification } from "antd";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import PipelineSection from "../../components/PipelineSection/PipelineSection";
import { fetchWorkspaceById, createPipeline } from "../../connectors/api";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";

const PipelinesPageWrapper = styled.div`
  .header-button {
    background: linear-gradient(135deg, #4318FF 0%, #9747FF 100%);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 24px;
    transition: all 0.3s ease;
    
    &:hover {
      background: linear-gradient(135deg, #3311CC 0%, #8033FF 100%);
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(67, 24, 255, 0.2);
    }
  }

  .section-title {
    font-size: 28px;
    margin: 48px 0 24px;
    font-weight: 600;
    background: linear-gradient(135deg, #1A1A1A 0%, #4318FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.5px;
  }

  .no-items-text {
    color: #8C8C8C;
    font-size: 16px;
    margin: 32px 0;
    text-align: center;
    font-weight: 500;
    background: #F8F8F8;
    padding: 24px;
    border-radius: 12px;
    border: 1px dashed #E8E8E8;
  }

  .ant-modal {
    .ant-modal-content {
      border-radius: 16px;
      padding: 24px;
    }

    .ant-modal-header {
      border-bottom: none;
      padding: 0;
      margin-bottom: 24px;
    }

    .ant-modal-title {
      font-size: 24px;
      font-weight: 600;
      background: linear-gradient(135deg, #1A1A1A 0%, #4318FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .ant-form-item-label > label {
      font-weight: 500;
      color: #1A1A1A;
    }

    .ant-input, .ant-select-selector {
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 8px 12px;
      height: auto;
    }

    .ant-btn-primary {
      background: linear-gradient(135deg, #4318FF 0%, #9747FF 100%);
      border: none;
      border-radius: 8px;
      padding: 8px 24px;
      height: auto;
      font-weight: 500;
      
      &:hover {
        background: linear-gradient(135deg, #3311CC 0%, #8033FF 100%);
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(67, 24, 255, 0.2);
      }
    }
  }
`;

const PipelinesPage = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const [workspaceInfo, setWorkspaceInfo] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchWorkspaceById(workspaceId);
      setWorkspaceInfo(data);
    };
    fetchData();
  }, [workspaceId]);

  const handleCreatePipeline = async (values) => {
    try {
      await createPipeline(workspaceId, {
        name: values.name,
        type: values.type,
        status: 'draft',
        configuration: {}
      });
      notification.success({
        message: 'Pipeline created successfully'
      });
      setIsModalVisible(false);
      form.resetFields();
      // Refresh the page to show new pipeline
      window.location.reload();
    } catch (error) {
      notification.error({
        message: 'Error creating pipeline',
        description: error.message
      });
    }
  };

  return (
    <Layout 
      title={`${workspaceInfo?.name || 'Workspace'} - Agents`}
      headerChildren={
        <Button 
          className="header-button"
          size="large"
          onClick={() => navigate(getUrl(ROUTES.PIPELINE_NEW, { workspaceId }))}
        >
          Create Pipeline
        </Button>
      }
    >
      <PipelinesPageWrapper>
        <div>
          <h2 className="section-title">Agents</h2>
          <PipelineSection 
            type="agent"
            emptyMessage="You have no Agents created yet."
          />
        </div>

        <Modal
          title="Create New Pipeline"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            onFinish={handleCreatePipeline}
            layout="vertical"
          >
            <Form.Item
              name="name"
              label="Pipeline Name"
              rules={[{ required: true, message: 'Please input pipeline name!' }]}
            >
              <Input placeholder="Enter pipeline name" />
            </Form.Item>
            <Form.Item
              name="type"
              label="Pipeline Type"
              rules={[{ required: true, message: 'Please select pipeline type!' }]}
            >
              <Select placeholder="Select pipeline type">
                <Select.Option value="agent">Agent</Select.Option>
                <Select.Option value="assistant">Assistant</Select.Option>
                <Select.Option value="search">Search Engine</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Create Pipeline
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </PipelinesPageWrapper>
    </Layout>
  );
};

export default PipelinesPage;