import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Input, Row, Col, Card, notification, Modal, Form } from "antd";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { createPipeline, fetchPipelineTemplates } from "../../connectors/api";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";

const PageWrapper = styled.div`
  .section-title {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 500;
  }

  .pipeline-card {
    border-radius: 10px;
    cursor: pointer;
    height: 200px;
    transition: all 0.3s;
    position: relative;
    padding: 20px;

    &:hover {
      border-color: #4318FF;
    }

    &.selected {
      border-color: #4318FF;
      background: #F4F7FE;
    }

    .info-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      color: #8F9BBA;
    }

    .type-label {
      position: absolute;
      bottom: 16px;
      right: 16px;
      color: #8F9BBA;
    }
  }

  .button-group {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  .submit-button {
    background: #4318FF;
    color: white;
    &:hover {
      background: #3311CC;
      color: white;
    }
  }
`;

const baseTemplate = {
  id: "from-scratch",
  name: "Create from scratch",
  description: "Create your own Agent from scratch",
  type: "agent"
}

const NewPipelinePage = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const loadTemplates = async () => {
      const data = await fetchPipelineTemplates();
      setTemplates(data);
    };
    loadTemplates();
  }, []);

  const handleSubmit = async () => {
    setIsModalVisible(true);
  };

  const handleModalSubmit = async () => {
    try {
      const values = await form.validateFields();
      
      await createPipeline(workspaceId, {
        name: values.name,
        description: values.description,
        template_id: selectedTemplate?.id || 1,
        status: "draft"
      });
      
      notification.success({
        message: "Pipeline created successfully"
      });
      navigate(getUrl(ROUTES.PIPELINES, { workspaceId }));
    } catch (error) {
      if (error.errorFields) {
        notification.error({
          message: "Please fill in all required fields"
        });
      } else {
        notification.error({
          message: "Error creating pipeline",
          description: error.message
        });
      }
    }
  };

  return (
    <Layout title="New Pipeline">
      <PageWrapper>
      <div className="section-title">Create your Agent from scratch</div>
        <Row gutter={[24, 24]}>
          <Col span={8} key={baseTemplate.id}>
            <Card 
              className={`pipeline-card ${selectedTemplate?.id === baseTemplate.id ? 'selected' : ''}`}
              onClick={() => setSelectedTemplate(baseTemplate)}
            >
              <InfoCircleOutlined className="info-icon" />
              <h3>{baseTemplate.name}</h3>
              <p>{baseTemplate.description}</p>
              <div className="type-label">{baseTemplate.type}</div>
            </Card>
          </Col>
        </Row>

        <div className="section-title" style={{ marginTop: '48px' }}>Try out our Agents</div>
        <Row gutter={[24, 24]}>
          {templates.map(template => (
            <Col span={8} key={template.id}>
              <Card 
                className={`pipeline-card ${selectedTemplate?.id === template.id ? 'selected' : ''}`}
                onClick={() => setSelectedTemplate(template)}
              >
                <InfoCircleOutlined className="info-icon" />
                <h3>{template.name}</h3>
                <p>{template.description}</p>
                <div className="type-label">Agent</div>
              </Card>
            </Col>
          ))}
        </Row>

        <div className="button-group">
          <Button onClick={() => navigate(getUrl(ROUTES.PIPELINES, { workspaceId }))}>
            Cancel
          </Button>
          <Button className="submit-button" onClick={handleSubmit}>
            Submit
          </Button>
        </div>

        <Modal
          title="Create Pipeline"
          open={isModalVisible}
          onOk={handleModalSubmit}
          onCancel={() => setIsModalVisible(false)}
          okText="Create"
          okButtonProps={{ className: "submit-button" }}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="name"
              label="Pipeline Name"
              rules={[{ required: true, message: 'Please enter a pipeline name' }]}
            >
              <Input placeholder="Enter pipeline name" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description (Optional)"
            >
              <Input.TextArea placeholder="Enter description" />
            </Form.Item>
          </Form>
        </Modal>
      </PageWrapper>
    </Layout>
  );
};

export default NewPipelinePage;