import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 12px 24px;
  background-color: #182EAE; /* Blue background color */
  color: white;
  border: none;
  border-radius: 10px; /* More rounded corners */
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #266BD3; /* Darker blue on hover */
    transform: scale(1.05);
  }
`;

const Button = ({ onClick, children, className }) => (
  <StyledButton onClick={onClick} className={className}>
    {children}
  </StyledButton>
);

export default Button;