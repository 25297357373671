import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Row, Col, Statistic } from "antd";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { fetchPipelineById } from "../../connectors/api";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";

const InfoSection = styled.div`
  background: white;
  padding: 24px;
  border-radius: 10px;
  margin-bottom: 24px;

  .label {
    color: #8F9BBA;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .value {
    color: #2B3674;
    font-size: 16px;
    font-weight: 500;
  }
`;

const StatCard = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 16px;

  .stat-icon {
    background: #F4F7FE;
    padding: 12px;
    border-radius: 50%;
    color: #4318FF;
  }

  .stat-change {
    color: #05CD99;
    font-size: 12px;
  }
`;

const PipelineInfoPage = () => {
  const { workspaceId, pipelineId } = useParams();
  const navigate = useNavigate();
  const [pipeline, setPipeline] = useState(null);

  useEffect(() => {
    const loadPipeline = async () => {
      const data = await fetchPipelineById(workspaceId, pipelineId);
      setPipeline(data);
    };
    loadPipeline();
  }, [workspaceId, pipelineId]);

  return (
    <Layout
      title={`${pipeline?.name || 'Pipeline'} Information`}
      headerChildren={
        <>
          <Button 
            onClick={() => navigate(getUrl(ROUTES.PIPELINES, { workspaceId }))}
            style={{ marginRight: 16 }}
          >
            Go Back
          </Button>
          <Button 
            type="primary" 
            onClick={() => navigate(getUrl(ROUTES.PIPELINE_EDIT, { workspaceId, pipelineId }))}
          >
            Edit
          </Button>
        </>
      }
    >
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <InfoSection>
            <div>
              <div className="label">Pipeline Name</div>
              <div className="value">{pipeline?.name}</div>
            </div>
            <div style={{ marginTop: 16 }}>
              <div className="label">Type</div>
              <div className="value">{pipeline?.type}</div>
            </div>
            <div style={{ marginTop: 16 }}>
              <div className="label">Status</div>
              <div className="value">{pipeline?.status}</div>
            </div>
            <div style={{ marginTop: 16 }}>
              <div className="label">Runs last month</div>
              <div className="value">{pipeline?.runs_last_month || 0}</div>
            </div>
          </InfoSection>
        </Col>
        <Col span={12}>
          <InfoSection>
            <div>
              <div className="label">Created by</div>
              <div className="value">{pipeline?.created_by}</div>
            </div>
            <div style={{ marginTop: 16 }}>
              <div className="label">Created at</div>
              <div className="value">
                {new Date(pipeline?.created_at).toLocaleDateString()}
              </div>
            </div>
            <div style={{ marginTop: 16 }}>
              <div className="label">Last Modified</div>
              <div className="value">
                {new Date(pipeline?.updated_at).toLocaleDateString()}
              </div>
            </div>
            <div style={{ marginTop: 16 }}>
              <div className="label">Last Run</div>
              <div className="value">
                {pipeline?.last_run ? new Date(pipeline.last_run).toLocaleDateString() : 'Never'}
              </div>
            </div>
          </InfoSection>
        </Col>
      </Row>

      <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
        <Col span={8}>
          <StatCard>
            <div className="stat-icon">�</div>
            <div>
              <Statistic title="Usage" value={26} />
              <div className="stat-change">+23% since last month</div>
            </div>
          </StatCard>
        </Col>
        <Col span={8}>
          <StatCard>
            <div className="stat-icon">⚡</div>
            <div>
              <Statistic title="Latency" value={1.2} suffix="s" />
              <div className="stat-change">+23% since last month</div>
            </div>
          </StatCard>
        </Col>
        <Col span={8}>
          <StatCard>
            <div className="stat-icon">✨</div>
            <div>
              <Statistic title="Success" value={90} suffix="%" />
              <div className="stat-change">+23% since last month</div>
            </div>
          </StatCard>
        </Col>
      </Row>
    </Layout>
  );
};

export default PipelineInfoPage;