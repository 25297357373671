import React from "react";
import { useNavigate } from "react-router-dom";

import PipelineCard from "../../components/PipelineCard/PipelineCard";
import { getUrl } from "../../helpers/navigation";
import { ROUTES } from "../../constants/routes";

const WorkspaceInfoPipelines = ({ pipelines, workspaceId }) => {
  const navigate = useNavigate();

  const handleNewPipeline = () => {
    navigate(getUrl(ROUTES.PIPELINE_NEW, { workspaceId }));
  };

  return (
    <div>
      <h2 className="section-title">Recent Pipelines</h2>
      {pipelines?.length > 0 ? (
        <div className="items-grid">
          {pipelines.map(({ id, name, updated_at, knowledge_base }) => (
            <PipelineCard
              id={id}
              key={id}
              knowledgeBase={knowledge_base?.name}
              lastModified={new Date(updated_at).toLocaleDateString()}
              title={name}
            />
          ))}
        </div>
      ) : (
        <p className="no-items-text" onClick={handleNewPipeline}>
          No pipelines created yet. Click here to start building your first pipeline!
        </p>
      )}
    </div>
  );
};

export default WorkspaceInfoPipelines;
