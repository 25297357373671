import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { RobotOutlined } from "@ant-design/icons";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";

const PipelineCardStyledWrapper = styled.div`
  width: 350px;
  height: 150px;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
  background: linear-gradient(to bottom right, #ffffff, #fafafa);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(8px);
  cursor: pointer;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to bottom right, #ffffff, #f5f5f5);
  }

  .pipeline-card-info-container {
    padding: 24px 20px;
    text-align: left;

    .pipeline-card-title {
      margin: 0 0 12px;
      font-size: 22px;
      font-weight: 600;
      color: #1a1a1a;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      letter-spacing: -0.5px;
    }

    .pipeline-card-subtitle {
      margin: 0;
      color: #666;
      font-size: 14px;
      font-weight: 500;
      opacity: 0.8;
      transition: opacity 0.3s ease;
    }

    .pipeline-card-knowledge-base {
      margin: 8px 0 0;
      color: #666;
      font-size: 14px;
      font-weight: 500;
      opacity: 0.8;
      transition: opacity 0.3s ease;
    }
  }

  .card-type {
    padding: 12px 20px;
    color: #666;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 6px;
    opacity: 0.7;
    transition: opacity 0.3s ease;
    justify-content: flex-end;
  }

  &:hover .pipeline-card-subtitle,
  &:hover .pipeline-card-knowledge-base,
  &:hover .card-type {
    opacity: 1;
  }
`;

const PipelineCard = ({ id, title, lastModified, knowledgeBase }) => {
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  const handleClick = () => {
    navigate(getUrl(ROUTES.PIPELINE_INFO, { workspaceId, pipelineId: id }));
  };

  return (
    <PipelineCardStyledWrapper onClick={handleClick}>
      <div className="pipeline-card-info-container">
        <h3 className="pipeline-card-title" title={title}>
          {title}
        </h3>
        <p className="pipeline-card-subtitle" title={lastModified}>
          Last Modified: {lastModified}
        </p>
        {knowledgeBase && (
          <p className="pipeline-card-knowledge-base" title={knowledgeBase}>
            {knowledgeBase}
          </p>
        )}
      </div>
      <div className="card-type">
        <RobotOutlined />
        Agent
      </div>
    </PipelineCardStyledWrapper>
  );
};

export default PipelineCard;