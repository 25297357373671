import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchPipelines } from "../../connectors/api";
import styled from "styled-components";
import PipelineCard from "../PipelineCard/PipelineCard";

const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  margin-bottom: 40px;

  .empty-message {
    color: #666;
    font-size: 16px;
  }

  /* Ensure minimum height for cards */
  > div {
    min-height: 180px;
  }
`;

const PipelineSection = ({ type, emptyMessage }) => {
  const { workspaceId } = useParams();
  const [pipelines, setPipelines] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPipelines = async () => {
      try {
        const data = await fetchPipelines(workspaceId);
        setPipelines(data);
      } catch (error) {
        console.error('Error loading pipelines:', error);
      } finally {
        setLoading(false);
      }
    };

    loadPipelines();
  }, [workspaceId, type]);

  if (loading) {
    return <SectionWrapper>Loading...</SectionWrapper>;
  }

  if (pipelines.length === 0) {
    return (
      <SectionWrapper>
        <p className="empty-message">{emptyMessage || 'No items found'}</p>
      </SectionWrapper>
    );
  }

  return (
    <SectionWrapper>
      {pipelines.map(pipeline => (
        <PipelineCard 
          key={pipeline.id} 
          id={pipeline.id}
          title={pipeline.name}
          lastModified={new Date(pipeline.updated_at).toLocaleDateString()}
          knowledgeBase={pipeline.configuration?.knowledge_base || 'No Knowledge Base'}
        />
      ))}
    </SectionWrapper>
  );
};

export default PipelineSection;