import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import { ExperimentOutlined } from "@ant-design/icons";

import { getUrl } from "../../helpers/navigation";
import { ROUTES } from "../../constants/routes";

const ProjectCardStyledWrapper = styled.div`
  width: 350px;
  height: 150px;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
  background: linear-gradient(to bottom right, #ffffff, #fafafa);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(8px);

  & > a {
    text-decoration: none !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to bottom right, #ffffff, #f5f5f5);
  }

  .project-card-info-container {
    padding: 24px 20px;
    text-align: left;

    .project-card-title {
      margin: 0 0 12px;
      font-size: 22px;
      font-weight: 600;
      color: #1a1a1a;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      letter-spacing: -0.5px;
    }

    .project-card-subtitle {
      margin: 0;
      color: #666;
      font-size: 14px;
      font-weight: 500;
      opacity: 0.8;
      transition: opacity 0.3s ease;
    }
  }

  .card-type {
    padding: 12px 20px;
    color: #666;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 6px;
    opacity: 0.7;
    transition: opacity 0.3s ease;
    justify-content: flex-end;
  }

  &:hover .project-card-subtitle,
  &:hover .card-type {
    opacity: 1;
  }
`;

const ProjectCard = ({ project: { id, title, name, created_at, image, workspaceId } }) => (
  <ProjectCardStyledWrapper>
    <Link to={getUrl(ROUTES.PROJECT_DASHBOARD, { workspaceId, id })}>
      <div className="project-card-info-container">
        <h3 className="project-card-title" title={name}>
          {name}
        </h3>
        <p
          className="project-card-subtitle"
          title={dayjs(created_at).format("DD of MMMM YYYY, HH:mm:ss")}
        >
          Created {dayjs(created_at).format("DD of MMMM YYYY")}
        </p>
      </div>
      <div className="card-type">
        <ExperimentOutlined />
        Model
      </div>
    </Link>
  </ProjectCardStyledWrapper>
);

export default ProjectCard;
