import React from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import Sidebar from "../Sidebar/Sidebar";

const LayoutStyledWrapper = styled.div`
  display: flex;
  width: 100%;

  .layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .layout-header-title {
      font-size: 32px;
      color: #333;
      margin: 0;
    }

    .layout-header-title-actions {
      display: flex;
      gap: 20px;
    }
  }

  .layout-main-content {
    flex-grow: 1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .layout-loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Layout = ({ children, headerChildren, isLoading, title }) => (
  <LayoutStyledWrapper>
    <Sidebar />
    <div className="layout-main-content">
      <div className="layout-header">
        <h2 className="layout-header-title">{title}</h2>
        <span className="layout-header-title-actions">{headerChildren}</span>
      </div>
      {isLoading ? (
        <div className="layout-loading-container">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      ) : (
        <div>{children}</div>
      )}
    </div>
  </LayoutStyledWrapper>
);

export default Layout;
