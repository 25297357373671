import React, { useRef } from "react";
import { useParams } from "react-router-dom";

import styled from "styled-components";
import { useState, useEffect } from "react";

import DataPageTriggerButtons from "./DataPageTriggerButtons";
import Layout from "../../components/Layout/Layout";
import { fetchProjectInfoById } from "../../connectors/api";
import { fetchLoginLabelStudio } from "../../connectors/auth";

const MAX_NUMBER_OF_RETRIES = 5;

const DataIframe = styled.iframe`
  flex-grow: 1;
  width: 100%;
  height: calc(100vh - 270px);
  border: none;
`;

const FailedLogInStyledWrapper = styled.div`
  max-width: 600px;
  margin: auto;
  text-align: center;

  .failed-login-image {
    margin: 50px 0;
  }

  .failed-login-title {
    margin-bottom: 20px;
  }

  .failed-login-subtitle {
    line-height: 25px;
  }
`;

const DataPage = () => {
  const { workspaceId, id } = useParams();
  const iframeRef = useRef(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLogginIn, setIsLogginIn] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [projectName, setProjectName] = useState(null);
  const [labelStudioProjectId, setLabelStudioProjectId] = useState(null);

  useEffect(() => {
    let count = 0;

    const loginLabelStudio = async () => {
      if (count < MAX_NUMBER_OF_RETRIES) {
        try {
          count += 1;
          await fetchLoginLabelStudio();
          setIsLogginIn(false);
          setIsLoggedIn(true);
        } catch (error) {
          console.error("Retrying loggin in to Label Studio", count);
          loginLabelStudio();
        }
      } else {
        setIsLogginIn(false);
      }
    };

    loginLabelStudio();
  }, []);

  useEffect(() => {
    const fetchProjectInfo = async () => {
      try {
        const response = await fetchProjectInfoById(workspaceId, id);
        setProjectName(response.name);
        setLabelStudioProjectId(response.label_studio_project_id);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch Label Studio project ID:", error);
        setIsLoading(false);
      }
    };

    fetchProjectInfo();
  }, [id, workspaceId]);

  const hideUnwantedIframeElements = (iframe) => {
    if (iframe) {
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;

      const userMenu = iframeDocument.querySelector("div.ls-menu-header");
      if (userMenu) {
        userMenu.style.display = "none";
      }

      // TODO: find a better way to deal with the loading screen
      let count = 0;
      const deleteUnwantedButtons = () => {
        if (count < MAX_NUMBER_OF_RETRIES) {
          count += 1;
          const panelButtons = iframeDocument.querySelectorAll(
            "div.dm-tab-panel .dm-button.dm-button_size_medium"
          );

          if (panelButtons?.length) {
            for (let i = 0; i < panelButtons?.length; i += 1) {
              if (["Export", "Import"].includes(panelButtons[i].textContent)) {
                panelButtons[i].style.display = "none";
              }
            }
          } else {
            console.log("Tried again removing buttons from iFrame");
            setTimeout(() => {
              deleteUnwantedButtons();
            }, 300);
          }
        }
      };

      deleteUnwantedButtons();
    }
  };

  return (
    <Layout
      title={projectName}
      isLoading={isLoading || isLogginIn}
      headerChildren={<DataPageTriggerButtons workspaceId={workspaceId} projectId={id} iframeRef={iframeRef} />}
    >
      {isLoggedIn ? (
        <DataIframe
          ref={iframeRef}
          src={`http://localhost/label-studio/projects/${labelStudioProjectId}`}
          title="Project data"
          onLoad={(e) => hideUnwantedIframeElements(e.target)}
        />
      ) : (
        <FailedLogInStyledWrapper>
          <img
            className="failed-login-image"
            src="http://localhost/label-studio/static/images/opossum_broken.df8566d9c026.svg"
            alt="login-failed"
          />
          <h2 className="failed-login-title">
            Label studio is failing to Log In
          </h2>
          <p className="failed-login-subtitle">
            Please try to reload this page or contact us to resolve your problem
            as soon as possible! Sorry for inconveniences and have a great day!
          </p>
        </FailedLogInStyledWrapper>
      )}
    </Layout>
  );
};

export default DataPage;
