import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Form, Input, Select, Row, Col, notification } from "antd";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { fetchPipelineById, updatePipeline, deletePipeline } from "../../connectors/api";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";

const EditPageWrapper = styled.div`
  .info-box {
    background: white;
    padding: 24px;
    border-radius: 10px;
    margin-bottom: 24px;
  }

  .label {
    color: #8F9BBA;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .value {
    color: #2B3674;
    font-size: 16px;
  }

  .delete-button {
    background-color: #FF0000;
    color: white;
    border: none;
    &:hover {
      background-color: #CC0000;
      color: white;
    }
  }

  .save-button {
    background-color: #4318FF;
    color: white;
    border: none;
    &:hover {
      background-color: #3311CC;
      color: white;
    }
  }
`;

const EditPipelinePage = () => {
  const { workspaceId, pipelineId } = useParams();
  const navigate = useNavigate();
  const [pipeline, setPipeline] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const loadPipeline = async () => {
      const data = await fetchPipelineById(workspaceId, pipelineId);
      setPipeline(data);
      form.setFieldsValue({
        name: data.name,
        type: data.type,
        status: data.status
      });
    };
    loadPipeline();
  }, [workspaceId, pipelineId, form]);

  const handleSave = async (values) => {
    try {
      await updatePipeline(workspaceId, pipelineId, values);
      notification.success({ message: 'Pipeline updated successfully' });
      navigate(getUrl(ROUTES.PIPELINE_INFO, { workspaceId, pipelineId }));
    } catch (error) {
      notification.error({ message: 'Error updating pipeline' });
    }
  };

  const handleDelete = async () => {
    try {
      await deletePipeline(workspaceId, pipelineId);
      notification.success({ message: 'Pipeline deleted successfully' });
      navigate(getUrl(ROUTES.PIPELINES, { workspaceId }));
    } catch (error) {
      notification.error({ message: 'Error deleting pipeline' });
    }
  };

  return (
    <Layout title="Edit Pipeline">
      <EditPageWrapper>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSave}
        >
          <Row gutter={24}>
            <Col span={12}>
              <div className="info-box">
                <Form.Item
                  name="name"
                  label="Pipeline Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="type"
                  label="Type"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="status"
                  label="Status"
                >
                  <Input disabled />
                </Form.Item>
                <div className="label">Runs last month</div>
                <div className="value">{pipeline?.runs_last_month || 0}</div>
              </div>
            </Col>
            <Col span={12}>
              <div className="info-box">
                <div className="label">Created by</div>
                <div className="value">{pipeline?.created_by}</div>
                <div style={{marginTop: 16}}>
                  <div className="label">Created at</div>
                  <div className="value">
                    {pipeline?.created_at && new Date(pipeline.created_at).toLocaleDateString()}
                  </div>
                </div>
                <div style={{marginTop: 16}}>
                  <div className="label">Last Modified</div>
                  <div className="value">
                    {pipeline?.updated_at && new Date(pipeline.updated_at).toLocaleDateString()}
                  </div>
                </div>
                <div style={{marginTop: 16}}>
                  <div className="label">Last Run</div>
                  <div className="value">
                    {pipeline?.last_run ? new Date(pipeline.last_run).toLocaleDateString() : 'Never'}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row justify="space-between">
            <Button className="delete-button" onClick={handleDelete}>
              Delete
            </Button>
            <div>
              <Button 
                style={{ marginRight: 16 }} 
                onClick={() => navigate(getUrl(ROUTES.PIPELINE_INFO, { workspaceId, pipelineId }))}
              >
                Cancel
              </Button>
              <Button className="save-button" htmlType="submit">
                Save
              </Button>
            </div>
          </Row>
        </Form>
      </EditPageWrapper>
    </Layout>
  );
};

export default EditPipelinePage;