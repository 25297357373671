import styled from "styled-components";

const SidebarStyledWrapper = styled.div`
  width: 250px;
  min-width: 250px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .sidebar-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      width: 80px;
      margin-bottom: 10px;
    }

    .sidebar-platform-name {
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }
  }

  nav.sidebar-navigation {
    width: 100%;
    margin-top: 20px;

    ul.sidebar-navigation-list {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li.sidebar-navigation-item {
        &.submenu-item {
          // Additional styling if needed for submenu items
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
  }
`;

export default SidebarStyledWrapper;
