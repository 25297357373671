import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, notification } from "antd";
import { FaPlus } from "react-icons/fa";

import WorkspaceCard from "../../components/WorkspaceCard/WorkspaceCard";
import Layout from "../../components/Layout/Layout";
import { fetchWorkspaces } from "../../connectors/api";
import WorkspacesCreateModal from "./WorkspacesCreateModal";

const WorkspaceGrid = styled.div`
  display: flex;
  gap: 20px;
  justify-content: left;
  flex-wrap: wrap;
`;

const CreateFirstWorkspaceContainer = styled.div`
  background: #f8f8f8;
  border-radius: 12px;
  border: 1px dashed #e8e8e8;
  color: #8c8c8c;
  cursor: pointer;
  font-size: 18px;
  margin: 32px 0;
  padding: 24px;
  text-align: center;
`;

const WorkspacesPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [workspaces, setWorkspaces] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // TODO: check with Albert regarding pagination
  const fetchWorkspacesList = async () => {
    try {
      const response = await fetchWorkspaces();
      setWorkspaces(response?.workspaces || []);
      setIsLoading(false);
    } catch (error) {
      notification.error({
        message: "Failed to load workspaces!",
        description: "Please reload the page, or if the problem persists, contact the Somia team for assistance.",
      });
      console.error("Failed to fetch list of workspaces", error);
    }
  };

  useEffect(() => {
    fetchWorkspacesList();
  }, []);

  const handleSubmitSuccess = () => {
    fetchWorkspacesList();
    setIsModalVisible(false);
  };

  return (
    <Layout
      title="Your workspaces"
      isLoading={isLoading}
      headerChildren={
        workspaces.length > 0 && (
          <Button size="large" type="primary" onClick={() => setIsModalVisible(true)}>
            <FaPlus /> New Workspace
          </Button>
        )
      }
    >
      {workspaces.length ? (
        <WorkspaceGrid>
          {workspaces.map((workspace) => (
            <WorkspaceCard key={workspace.id} workspace={workspace} />
          ))}
        </WorkspaceGrid>
      ) : (
        <CreateFirstWorkspaceContainer onClick={() => setIsModalVisible(true)}>
          <p>You do not have any workspaces yet! Click here to start you journey and create a new workspace now!</p>
        </CreateFirstWorkspaceContainer>
      )}

      <WorkspacesCreateModal
        onCancel={() => setIsModalVisible(false)}
        onSubmitSuccess={handleSubmitSuccess}
        visible={isModalVisible}
      />
    </Layout>
  );
};

export default WorkspacesPage;
