import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchKnowledgeBaseList } from "../../connectors/api";
import styled from "styled-components";
import KnowledgeBaseCard from "../KnowledgeBaseCard/KnowledgeBaseCard";

const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 40px;

  .empty-message {
    color: #666;
    font-size: 16px;
  }
`;

const KnowledgeBaseSection = ({ emptyMessage }) => {
  const { workspaceId } = useParams();
  const [knowledgeBases, setKnowledgeBases] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadKnowledgeBases = async () => {
      try {
        const data = await fetchKnowledgeBaseList(workspaceId);
        setKnowledgeBases(data);
      } catch (error) {
        console.error('Error loading knowledge bases:', error);
      } finally {
        setLoading(false);
      }
    };

    loadKnowledgeBases();
  }, [workspaceId]);

  if (loading) {
    return <SectionWrapper>Loading...</SectionWrapper>;
  }

  if (knowledgeBases.length === 0) {
    return (
      <SectionWrapper>
        <p className="empty-message">{emptyMessage || 'No items found'}</p>
      </SectionWrapper>
    );
  }

  return (
    <SectionWrapper>
      {knowledgeBases.map(knowledgeBase => (
        <KnowledgeBaseCard 
          key={knowledgeBase.id}
          id={knowledgeBase.id}
          title={knowledgeBase.name}
          lastModified={new Date(knowledgeBase.created_at).toLocaleDateString()}
        />
      ))}
    </SectionWrapper>
  );
};

export default KnowledgeBaseSection;