import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Card, Row, Col } from "antd";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { fetchWorkspaceById } from "../../connectors/api";
import PipelineCard from "../../components/PipelineCard/PipelineCard";
import KnowledgeBaseCard from "../../components/KnowledgeBaseCard/KnowledgeBaseCard";

const AutomationPageWrapper = styled.div`
  .header-button {
    background: linear-gradient(135deg, #4318FF 0%, #9747FF 100%);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 24px;
    transition: all 0.3s ease;
    
    &:hover {
      background: linear-gradient(135deg, #3311CC 0%, #8033FF 100%);
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(67, 24, 255, 0.2);
    }
  }

  .section-title {
    font-size: 28px;
    margin: 48px 0 24px;
    font-weight: 600;
    background: linear-gradient(135deg, #1A1A1A 0%, #4318FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.5px;
  }

  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 24px;
    padding: 8px 4px;
  }

  .info-card {
    width: 100%;
    border-radius: 16px;
    background: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    
    &:hover {
      transform: translateY(-6px);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    }
  }

  .no-items-text {
    color: #8C8C8C;
    font-size: 16px;
    margin: 32px 0;
    text-align: center;
    font-weight: 500;
    background: #F8F8F8;
    padding: 24px;
    border-radius: 12px;
    border: 1px dashed #E8E8E8;
  }
`;

const AutomationPage = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const [workspaceInfo, setWorkspaceInfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchWorkspaceById(workspaceId);
      setWorkspaceInfo(data);
    };
    fetchData();
  }, [workspaceId]);

  return (
    <Layout 
      title={`${workspaceInfo?.name || 'Automation'}`}
      headerChildren={
        <Button 
          className="header-button"
          onClick={() => navigate(-1)}
          size="large"
        >
          Back
        </Button>
      }
    >
      <AutomationPageWrapper>
        <div>
          <h2 className="section-title">Recent Pipelines</h2>
          {workspaceInfo?.pipelines?.length > 0 ? (
            <Row className="card-grid">
              {workspaceInfo.pipelines.map((pipeline) => (
                <Col key={pipeline.id}>
                  <PipelineCard
                    id={pipeline.id}
                    title={pipeline.name}
                    lastModified={new Date(pipeline.updated_at).toLocaleDateString()}
                    knowledgeBase={pipeline.knowledge_base?.name}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <p className="no-items-text">No pipelines created yet. Start building your first pipeline!</p>
          )}
        </div>
        <div>
          <h2 className="section-title">Recent Knowledge Bases</h2>
          {workspaceInfo?.knowledge_bases?.length > 0 ? (
            <Row className="card-grid">
              {workspaceInfo.knowledge_bases.map((kb) => (
                <Col key={kb.id}>
                  <KnowledgeBaseCard
                    id={kb.id}
                    title={kb.name}
                    lastModified={new Date(kb.updated_at).toLocaleDateString()}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <p className="no-items-text">No knowledge bases yet. Create one to enhance your workspace!</p>
          )}
        </div>
      </AutomationPageWrapper>
    </Layout>
  );
};

export default AutomationPage;