import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Modal, Form, Input, notification } from "antd";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import KnowledgeBaseSection from "../../components/KnowledgeBaseSection/KnowledgeBaseSection";
import { fetchWorkspaceById, createKnowledgeBase } from "../../connectors/api";

const KnowledgeBasePageWrapper = styled.div`
  .header-button {
    background: linear-gradient(135deg, #4318FF 0%, #9747FF 100%);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 24px;
    transition: all 0.3s ease;
    
    &:hover {
      background: linear-gradient(135deg, #3311CC 0%, #8033FF 100%);
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(67, 24, 255, 0.2);
    }
  }

  .section-title {
    font-size: 28px;
    margin: 48px 0 24px;
    font-weight: 600;
    background: linear-gradient(135deg, #1A1A1A 0%, #4318FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.5px;
  }

  .ant-modal {
    .ant-modal-content {
      border-radius: 16px;
      padding: 24px;
    }

    .ant-modal-header {
      border-bottom: none;
      padding: 0;
      margin-bottom: 24px;
    }

    .ant-modal-title {
      font-size: 24px;
      font-weight: 600;
      background: linear-gradient(135deg, #1A1A1A 0%, #4318FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .ant-form-item-label > label {
      font-weight: 500;
      color: #1A1A1A;
    }

    .ant-input {
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 8px 12px;
      height: auto;
    }

    .ant-btn-primary {
      background: linear-gradient(135deg, #4318FF 0%, #9747FF 100%);
      border: none;
      border-radius: 8px;
      padding: 8px 24px;
      height: auto;
      font-weight: 500;
      
      &:hover {
        background: linear-gradient(135deg, #3311CC 0%, #8033FF 100%);
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(67, 24, 255, 0.2);
      }
    }
  }
`;

const KnowledgeBasePage = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const [workspaceInfo, setWorkspaceInfo] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchWorkspaceById(workspaceId);
      setWorkspaceInfo(data);
    };
    fetchData();
  }, [workspaceId]);

  const handleCreateKnowledgeBase = async (values) => {
    try {
      await createKnowledgeBase(workspaceId, {
        name: values.name,
      });
      notification.success({
        message: 'Knowledge Base created successfully'
      });
      setIsModalVisible(false);
      form.resetFields();
      // Refresh the page to show new knowledge base
      window.location.reload();
    } catch (error) {
      notification.error({
        message: 'Error creating knowledge base',
        description: error.message
      });
    }
  };

  return (
    <Layout 
      title={`${workspaceInfo?.name || 'Workspace'} - Knowledge Bases`}
      headerChildren={
        <Button 
          className="header-button" 
          size="large"
          onClick={() => setIsModalVisible(true)}
        >
          Create Knowledge Base
        </Button>
      }
    >
      <KnowledgeBasePageWrapper>
        <div>
          <h2 className="section-title">Knowledge Bases</h2>
          <KnowledgeBaseSection 
            type="knowledge_base" 
            emptyMessage="No knowledge bases yet. Create one to enhance your workspace!" 
          />
        </div>

        <Modal
          title="Create New Knowledge Base"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            onFinish={handleCreateKnowledgeBase}
            layout="vertical"
          >
            <Form.Item
              name="name"
              label="Knowledge Base Name"
              rules={[{ required: true, message: 'Please input knowledge base name!' }]}
            >
              <Input placeholder="Enter knowledge base name" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create Knowledge Base
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </KnowledgeBasePageWrapper>
    </Layout>
  );
};

export default KnowledgeBasePage;