import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, Col, notification, Row } from "antd";

import Layout from "../../components/Layout/Layout";
import ModelsTable from "../../components/ModelsTable/ModelsTable";
import {
  fetchProjectInfoById,
  fetchProjectModelData,
  fetchTriggerEvalModel,
  fetchTriggerTrainModel,
} from "../../connectors/api";

// TODO: remove when we are sure we do not need it anymore
// const ModelsIframe = styled.iframe`
//   width: 100%;
//   height: calc(100vh - 200px);
//   border: none;
// `;

const ModelsPageStyledWrapper = styled.div`
  .models-page-row {
    margin-bottom: 25px;
  }

  .ant-card {
    height: 100%;

    .card-title {
      margin-bottom: 15px;
    }
  }
`;

const ModelsPage = () => {
  const { workspaceId, id } = useParams();
  const [api, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(true);
  const [projectName, setProjectName] = useState(null);
  const [modelsData, setModelsData] = useState({
    eval: { data: null, isLoading: true },
    train: { data: null, isLoading: true },
  });
  const [isEvalModelDisabled, setIsEvalModelDisabled] = useState(false);
  const [isTrainModelDisabled, setIsTrainModelDisabled] = useState(false);

  useEffect(() => {
    const fetchProjectName = async () => {
      try {
        const response = await fetchProjectInfoById(workspaceId, id);
        setProjectName(response.name);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch Label Studio project ID:", error);
        setIsLoading(false);
      }
    };

    fetchProjectName();
  }, [id]);

  useEffect(() => {
    const fetchModelData = async (type) => {
      try {
        const response = await fetchProjectModelData(workspaceId, id, type);
        console.log("fetchModelData", { type, response });
        setModelsData((prevState) => ({
          ...prevState,
          [type]: { data: response, isLoading: false },
        }));
      } catch (error) {
        console.error(`Failed to fetch model data for ${type}:`, error);
        setModelsData((prevState) => ({
          ...prevState,
          [type]: { data: null, isLoading: false },
        }));
      }
    };

    fetchModelData("eval");
    fetchModelData("train");
  }, [id]);

  const handleTrainModel = async () => {
    setIsTrainModelDisabled(true);
    try {
      const response = await fetchTriggerTrainModel(workspaceId, id);
      console.log("Model training initiated:", response);
      api.success({ message: response.message });
      setIsTrainModelDisabled(false);
    } catch (error) {
      console.error("Error initiating model training:", error);
      api.error({ message: error.message });
      setIsTrainModelDisabled(false);
    }
  };

  const handleEvalModel = async () => {
    setIsEvalModelDisabled(true);
    try {
      const response = await fetchTriggerEvalModel(workspaceId, id);
      console.log("Model evaluation initiated:", response);
      api.success({ message: response.message });
      setIsEvalModelDisabled(false);
    } catch (error) {
      console.error("Error initiating model evaluation:", error);
      api.error({ message: error.message });
      setIsEvalModelDisabled(false);
    }
  };

  const isPageLoading =
    isLoading || modelsData.eval.isLoading || modelsData.train.isLoading;

  return (
    <Layout
      title={projectName}
      isLoading={isPageLoading}
      headerChildren={
        <>
          <Button
            disabled={isTrainModelDisabled}
            onClick={handleTrainModel}
            type="primary"
            size="large"
          >
            Train Model
          </Button>
          <Button
            disabled={isEvalModelDisabled}
            onClick={handleEvalModel}
            type="primary"
            size="large"
          >
            Eval Model
          </Button>
        </>
      }
    >
      {contextHolder}

      <ModelsPageStyledWrapper>
        <Row gutter={[16]} className="models-page-row">
          <Col xs={12}>
            <Card>
              <Card.Meta title="Train Models" className="card-title" />
              <ModelsTable data={modelsData.train.data} noScroll />
            </Card>
          </Col>
          <Col xs={12}>
            <Card>
              <Card.Meta title="Eval Models" className="card-title" />
              <ModelsTable data={modelsData.eval.data} noScroll />
            </Card>
          </Col>
        </Row>
        {/* <Row gutter={[16]} className="models-page-row"> // TODO: remove when we are sure we do not need it anymore
          <Col xs={24}>
            <ModelsIframe
              src="http://localhost:5001/#/experiments/0"
              title="Models"
            />
          </Col>
        </Row> */}
      </ModelsPageStyledWrapper>
    </Layout>
  );
};

export default ModelsPage;
